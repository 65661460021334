import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

function Header() {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    navigate('/');
  };

  

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <div className="navbar-brand">
          <img src="/header-logo.png" alt="" width="70" height="70"/>
        </div>
          <Link className="navbar-brand kanit-regular text-center" to="/" style={{ fontSize: '62px' }}>Replay Edge</Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
          {isLoggedIn ? (
            <button className="btn btn-outline-primary" onClick={handleLogout}>Logout</button>
          ) : (
            <Link to="/login" className="btn btn-outline-primary btn-lg">Login</Link>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Header;