import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import Header from './Header';

export default function ClientVideos() {
    const { clientId, folder } = useParams();
    const [videos, setVideos] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);

    useEffect(() => {
        const fetchVideos = async () => {
            const response = await fetch(`https://instant-replay-436813.uc.r.appspot.com/api/clients/${clientId}/dates/${folder}/videos/`);
            const data = await response.json();
            setVideos(data);
        };

        fetchVideos();
    }, [clientId, folder]);

    const handleVideoClick = (video) => {
        setSelectedVideo(video);
    };

    const closeModal = () => {
        setSelectedVideo(null);
    };

    return (
        <>
            <Header />
            <div className="container mt-4">
                <h1 className="mb-4 text-center kanit-bold">Videos for {clientId} on {folder}</h1>
                <Link to={`/client/${clientId}/folders`} className="btn btn-outline-primary mb-3">
                    <i className="bi bi-arrow-left"></i> Back to Folders
                </Link>
                <div className="row">
                    {videos.length > 0 ? (
                        videos.map(video => (
                            <div key={video.name} className="col-md-4 mb-4">
                                <div className="card h-100 shadow-sm" onClick={() => handleVideoClick(video)} style={{ cursor: 'pointer' }}>
                                    <div className="card-img-top" style={{ height: '200px', position: 'relative' }}>
                                        <ReactPlayer
                                            url={video.url}
                                            width="100%"
                                            height="100%"
                                            light={video.url || true}
                                            preload="metadata"
                                            // playIcon={<i className="bi bi-play-circle-fill text-white" style={{ fontSize: '3rem' }}></i>}
                                            config={{
                                                file: {
                                                    attributes: {
                                                        poster: video.url
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title">{video.name.split('/').pop()}</h5>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="col-12 text-center">No videos available for this folder.</p>
                    )}
                </div>
            </div>

            {selectedVideo && (
                <div className="modal show" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }} onClick={closeModal}>
                    <div className="modal-dialog modal-lg" onClick={e => e.stopPropagation()}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{selectedVideo.name.split('/').pop()}</h5>
                                <button type="button" className="btn-close" onClick={closeModal}></button>
                            </div>
                            <div className="modal-body">
                                <ReactPlayer
                                    url={selectedVideo.url}
                                    width="100%"
                                    height="auto"
                                    controls={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <footer className="bg-dark text-white text-center py-3 mt-5 fixed-bottom">
                <p className="mb-0">&copy; 2024 Replay Edge</p>
            </footer>
        </>
    );
}
