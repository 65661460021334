import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Header from './Header';

export default function ClientFolders() {
    const [folders, setFolders] = useState([]);
    const { clientId } = useParams();

    console.log(clientId);

    useEffect(() => {
        fetch(`https://instant-replay-436813.uc.r.appspot.com/api/clients/${clientId}/dates/`)
            .then(response => response.json())
            .then(data => {
                // Sort folders in descending order
                console.log(data);
                const sortedFolders = data.sort((a, b) => b.localeCompare(a));
                setFolders(sortedFolders);
            });
    }, [clientId]);

    const formatDateForDisplay = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${month}/${day}/${year}`;
    };

    return (
        <>
            <Header />
            <div className="container mt-5">
                <Link to="/" className="btn btn-outline-primary mb-3">
                    <i className="bi bi-arrow-left"></i> Back to Clients
                </Link>
                <h2 className="mb-4">{clientId} Folders</h2>
                <div className="row">
                    {folders.map(folder => (
                        <div key={folder} className="col-md-4 mb-4">
                            <Link to={`/clients/${clientId}/dates/${folder}/videos`} className="text-decoration-none">
                                <div className="card h-100 shadow-sm hover-effect">
                                    <div className="card-body d-flex flex-column align-items-center">
                                        <i className="bi bi-folder fs-1 mb-3"></i>
                                        <h5 className="card-title text-center">{formatDateForDisplay(folder)}</h5>
                                        <p className="card-text text-center text-muted">View videos</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            <footer className="bg-dark text-white text-center py-3 mt-5 fixed-bottom">
                <p className="mb-0">&copy; 2024 Replay Edge</p>
            </footer>
        </>
    );
}
