import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Videos from './components/Videos';
import ClientVideos from './components/ClientVideos';
import ClientFolders from './components/ClientFolders';
import Login from './components/Login';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Videos />} />
        <Route path="/login" element={<Login />} />
        <Route path="/clients/:clientId/dates/:folder/videos" element={<ClientVideos />} />
        <Route path="/client/:clientId/folders" element={<ClientFolders />} />
      </Routes>
    </Router>
  );
}

export default App;
