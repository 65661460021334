import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Header from './Header';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send a request to your backend to authenticate
    // For now, we'll just simulate a successful login
    localStorage.setItem('isLoggedIn', 'true');
    const from = location.state?.from || '/';
    navigate(from);
  };

  return (
    <>
      <Header />
      <div className="container mt-5">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="username" className="form-label">Username</label>
            <input
              type="text"
              className="form-control"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="d-flex">
            <button type="submit" className="btn btn-primary m-1">Login</button>
            <Link to="/" className="btn btn-outline-primary m-1">
              Back
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}

export default Login;